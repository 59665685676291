import { useRouter } from 'next/router';
import { defineMessages, useIntl } from 'react-intl';
import Head from 'next/head';
import TitheEnvelope from '@components/templates/donate/tithe-envelope';

export const titleMessage = defineMessages({
  title: {
    id: 'p0nCGh',
    defaultMessage: 'Donate',
    description: 'Title for Donate page.',
  },
});
const DonateTitheEnvelopePage = () => {
  const router = useRouter();
  const intl = useIntl();
  const orgId = router.query.orgId as string | undefined;
  return (
    <>
      <Head>
        <title>
          {`AdventistGiving - ${intl.formatMessage(titleMessage.title)}`}
        </title>
      </Head>
      {orgId && <TitheEnvelope orgId={orgId} />}
    </>
  );
};

export default DonateTitheEnvelopePage;
